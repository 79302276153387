<template>
	<div>
		<h2>
			{{ $t("monte.stock_semence.for_stallion") }} {{ horse.horse_nom }}
			<template v-if="tiers">
				- {{ tiers.tiers_rs }}
			</template>
		</h2>
		<CustomTable
			id_table="stock_stallion"
			ref="table"
			:items="stocks"
			:busy.sync="table_busy"
			primaryKey="uid"
	        :hrefsRoutes="config_table_hrefs"
			:externSlotColumns="['couleur']"
		>
			<template v-slot:custom-slot-cell(couleur)="{ data }">
				{{ $t('monte.couleur.' + data.couleur.semencecouleur_code) }}
			</template>
		</CustomTable>

		<b-modal ref="modalDestroy" hide-footer>
			<template v-slot:modal-title>
				<template>
					{{ $t("monte.stock_semence.destroy_stock") }}
				</template>
			</template>

			<div v-for="stock in stock_to_delete" class="row mb-1" :key="stock.uid"> 
				<div class="col-12">
					<label>{{ $t('monte.stock_semence.numero_lot') }} : {{ stock.lot }}</label>
					<b-input-group :prepend="$t('monte.stock_semence.dispo') + stock.dispo">
						<b-input v-model="stock.stock_destroy" />
					</b-input-group>
				</div>
			</div>

			<b-button class="mt-3 btn-block rounded-pill" variant="primary" @click="destroyStock">{{ $t("global.valider") }} <font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse  class="mr-1"/></b-button>
		</b-modal>
	</div>
</template>

<script type="text/javascript">
import Shutter from '@/mixins/Shutter'
import ShutterSemence from '@/mixins/shutters-manager/Semence'
import StockSemence from '@/mixins/StockSemence'
import Navigation from "@/mixins/Navigation"
import { EventBus } from "EventBus";

	export default {
		name: 'StockStallionDetails',
		mixins: [Shutter, ShutterSemence, StockSemence, Navigation],
		props: ['horse', 'tiers', 'fiche'],
		data () {
			return {
				table_busy: false,
		        stocks: [],
		        stock_to_delete: [],
		        processing: false,
		        config_table_hrefs: {
					'to.tiers_rs': {
						routeUniqueName: 'tiersTableauBord',
						routeName: 'tiersTableauBord',
						params: {
							tiers_id: 'to.tiers_id'
						}
					},
				},
				events_tab: {
					'TableAction::goToAddStockStallion': this.openAddStockShutter,
					'TableAction::goToDestroyStock': this.openModalDestroy,
					'TableAction::goToEditStock': (stock) => {
                        this.openAddStockShutter(stock)
                    }
				},
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.table_busy = true
				this.stocks = await this.loadAllStockLocalDetailsByStallion(this.horse.horse_id)
				if(this.tiers) {
					this.stocks = this.stocks.filter(s => s.to.tiers_id == this.tiers.tiers_id)
				}
				this.table_busy = false
			},

			openAddStockShutter(stock = null) {
				this.setupAjoutStockStallion(this.horse, this.tiers, this.fiche, stock)
			},

			openModalDestroy(stocks) {
				this.stock_to_delete = stocks
				this.stock_to_delete.forEach(stock => {
					stock.stock_destroy = stock.dispo
				})
				this.$refs.modalDestroy.show()
			},

			async destroyStock() {
				this.processing = true
				const stock_to_send = this.stock_to_delete.map(stock => {
					return {
						uid: stock.uid,
						stock_destroy: stock.stock_destroy
					}
				})
				await this.destructionStock(stock_to_send)
				EventBus.$emit('TableAction::stopSpin', true)
				this.$refs.modalDestroy.hide()
				this.processing = false
				this.$refs.table.refreshTable()
				this.init_component()
			}
		},

		components: {
			CustomTable: () => import('GroomyRoot/components/Table/CustomTable')
		}
	}

</script>